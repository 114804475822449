/* eslint-disable react/forbid-prop-types */
import Skeleton from "@material-ui/lab/Skeleton";
import PropTypes from "prop-types";
import React from "react";
import DoughnutChartComponent from "../../../components/doughnut-chart-component";
import { formatNumberWithText } from "../../../utils/numberFormater";

const ChartContainer = ({
  isLoading,
  incomechartData = {},
  deptchartData = {},
}) => {
  const { chartData: deptChartData = [] } = deptchartData;
  const [deptChartTop = "No Data", deptChartBottom = " "] = deptChartData;
  const { chartData: incomeChartData = [] } = incomechartData;
  const [incomeChartTop = "No Data", incomeChartBottom = " "] = incomeChartData;
  return (
    <div className="da-chart-container">
      <div className="da-bar-chart-container da-doughnut-size">
        {isLoading ? (
          <div className="doughnut-chart-skeleton">
            <Skeleton
              animation="wave"
              variant="circle"
              width={120}
              height={120}
            />
            <Skeleton
              animation="wave"
              variant="text"
              width="100%"
              height={80}
            />
          </div>
        ) : (
          <DoughnutChartComponent
            data={incomechartData}
            textTop={`$${formatNumberWithText(incomeChartTop, 1)}`}
            textBottom={incomeChartBottom}
          />
        )}
      </div>
      <div className="da-bar-chart-container da-doughnut-size">
        {isLoading ? (
          <div className="doughnut-chart-skeleton">
            <Skeleton
              animation="wave"
              variant="circle"
              width={120}
              height={120}
            />
            <Skeleton
              animation="wave"
              variant="text"
              width="100%"
              height={80}
            />
          </div>
        ) : (
          <DoughnutChartComponent
            data={deptchartData}
            textTop={`${deptChartTop < 0 ? "-$" : "$"}${formatNumberWithText(
              Math.abs(deptChartTop),
              1
            )}`}
            textBottom={deptChartBottom}
          />
        )}
      </div>
    </div>
  );
};

export default ChartContainer;

ChartContainer.propTypes = {
  incomechartData: PropTypes.object,
  deptchartData: PropTypes.object,
};
