import classNames from "classnames";
import PropTypes from "prop-types";
import Rainbow from "rainbowvis.js";
import { useRef } from "react";
import { Doughnut } from "react-chartjs-2";
import { useIsTablet } from "../hooks/common.hooks";
import { borderColor } from "../scss/colors.scss";
import { fontBold } from "../scss/fonts.scss";
import { formatNumberWithDollar } from "../utils/numberFormater";
import { isNotEmptyObject } from "../utils/object-util";
import { getColorByPropertyValue } from "../utils/theme";

const getChartColorRange = (chartData, number) => {
  let arr = [];
  for (let i = 0; i < number; i += 1) {
    arr = arr.concat(`#${chartData.colorAt(i)}`);
  }
  return arr;
};

const generateChartColor = (data, chartColor) => {
  const rainbow = new Rainbow();
  rainbow.setSpectrum("#162029", borderColor);

  rainbow.setNumberRange(0, data.length > 1 ? data.length - 1 : 1);
  return chartColor.length > 0
    ? chartColor
    : getChartColorRange(rainbow, data.length);
};

const buildDoughnutChartOption = (
  textTop,
  textBottom,
  isBottomColor = true
) => {
  return {
    cutoutPercentage: 85,
    maintainAspectRatio: false,
    responsive: true,
    aspectRatio: 1,
    elements: {
      center: {
        textNumber: textTop,
        text: textBottom,
        fontColor: "#656566",
        topColor: "#131c24",
        bottomColor: isBottomColor
          ? getColorByPropertyValue("--primaryColor") || "#131c24"
          : "#131c24",
        fontStyle: "normal",
      },
    },
    legend: false,
    tooltips: {
      mode: "single",
      intersect: true,
      callbacks: {
        label: (tooltipItem, data) => {
          const chartValue = data.datasets[0].data[tooltipItem.index];
          const chartValueWithCommas = formatNumberWithDollar(chartValue);
          const chartTitle = data.labels[tooltipItem.index];
          return `${chartTitle}: ${chartValueWithCommas}`;
        },
      },

      backgroundColor: "#fff",
      titleFontFamily: "ProximaNovaRegular",
      titleFontSize: 14,
      titleFontColor: "#000",
      bodyFontFamily: `${fontBold}`,
      bodyFontColor: "#000",
      bodyFontSize: 13,
      displayColors: false,
      borderColor: "#000",
      borderWidth: 1,
    },
    hover: {
      animationDuration: 0, // duration of animations when hovering an item
      intersect: false,
    },
    layout: {
      padding: {},
    },
  };
};

const buildDoughnutChartData = (label, data, colors = []) => {
  const chartColor = generateChartColor(data, colors);
  return {
    type: "doughnut",
    labels: label,
    datasets: [
      {
        data,
        backgroundColor: chartColor,
        hoverBackgroundColor: chartColor,
      },
    ],
  };
};

const drawTextInsideDoughnutPlugin = {
  beforeDraw: (chart) => {
    if (chart.config.options.elements.center) {
      const { ctx } = chart.chart;
      ctx.restore();
      ctx.clearRect(0, 0, 500, 500);
      // Get options from the center object in options
      const centerConfig = chart.config.options.elements.center;
      let fontStyle = `${fontBold}`;
      const txt = centerConfig.textNumber;
      const { text } = centerConfig;
      const color = centerConfig.topColor;
      const maxFontSize = centerConfig.maxFontSize || 25;
      const sidePadding = centerConfig.sidePadding || 25;
      const sidePaddingCalculated =
        (sidePadding / 100) * (chart.innerRadius * 2);
      // Start with a base font of 30px
      ctx.font = `30px ${fontStyle}`;
      // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
      const stringWidth = ctx.measureText(txt).width;
      const elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;
      // Find out how much the font can grow in width.
      const widthRatio = elementWidth / stringWidth;
      const newFontSize = Math.floor(30 * widthRatio);
      const elementHeight = chart.innerRadius * 2;
      // Pick a new font size so it will not be larger than the height of label.
      let fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
      let { minFontSize } = centerConfig;
      const lineHeight = centerConfig.lineHeight || 18;
      let wrapText = false;
      if (minFontSize === undefined) {
        minFontSize = 16;
      }
      if (minFontSize && fontSizeToUse < minFontSize) {
        fontSizeToUse = minFontSize;
        wrapText = true;
      }
      // Set font settings to draw it correctly.
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
      let centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2.2;
      const centerYY = chart.chartArea.top + chart.chartArea.bottom / 1.65;
      ctx.font = `${fontSizeToUse}px ${fontStyle}`;
      ctx.fillStyle = color;
      if (!wrapText) {
        ctx.font = `${elementHeight / 6.5}px ${fontStyle}`;
        ctx.fillText(txt, centerX, centerY);
        fontStyle = "ProximaNovaRegular";
        ctx.fillStyle = centerConfig.bottomColor;
        ctx.font = `${elementHeight / 7.5}px ${fontStyle}`;
        ctx.fillText(text, centerX, centerYY);
        return;
      }
      const words = txt.split(" ");
      let line = "";
      const lines = [];
      // Break words up into multiple lines if necessary
      for (let n = 0; n < words.length; n += 1) {
        const testLine = `${line + words[n]} `;
        const metrics = ctx.measureText(testLine);
        const testWidth = metrics.width;
        if (testWidth > elementWidth && n > 0) {
          lines.push(line);
          line = `${words[n]} `;
        } else {
          line = testLine;
        }
      }
      // Move the center up depending on line height and number of lines
      centerY -= (lines.length / 2) * lineHeight;
      ctx.restore();
      for (let n = 0; n < lines.length; n += 1) {
        ctx.fillText(lines[n], centerX, centerY);
        centerY += lineHeight;
      }
      // Draw text in center.
      ctx.fillText(line, centerX, centerY);
      fontStyle = "ProximaNovaRegular";
      ctx.fillStyle = centerConfig.bottomColor;
      centerY += lineHeight;
      ctx.fillText(text, centerX, centerY);
      ctx.save();
    }
  },
};

const DoughnutChartComponent = ({
  title = "",
  data = {},
  textTop = " ",
  textBottom = " ",
  legendDisplay = true,
  customLegend = false,
  isBottomColor = false,
}) => {
  const {
    data: dChartData = [],
    title: dChartTitle = [],
    colors: dChartColor = [],
    icons: dChartIcons = [],
  } = data;
  const doughnut = useRef(null);
  const isTablet = useIsTablet();

  const chartOptions = buildDoughnutChartOption(
    textTop,
    textBottom,
    isBottomColor,
    customLegend,
    isTablet
  );
  let chartData = {};
  if (isNotEmptyObject(data)) {
    chartData = buildDoughnutChartData(dChartTitle, dChartData, dChartColor);
  }
  const chartColor = generateChartColor(dChartData, dChartColor);

  const generateLegend = () => {
    if (customLegend) {
      return dChartData.map((chartDataset, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div className="legend-wrap" key={`${chartDataset}${index}`}>
            <div className="legend-font legend-font-bold legend__title">
              <div>{dChartTitle[index] || ""}</div>

              {dChartIcons?.[index] && (
                <div className="legend__icon">{dChartIcons[index]}</div>
              )}
            </div>

            <div className="legend-box-container">
              <div
                className="legend-box"
                style={{
                  backgroundColor: `${chartColor[index]}`,
                }}
              />
            </div>
            <div className="legend-font">
              ${formatNumberWithDollar(chartDataset)}
            </div>
          </div>
        );
      });
    }
    return dChartData.map((chartDataset, index) => {
      return (
        <div className="legend-wrap" key={dChartTitle[index]}>
          <div
            className="legend-box"
            style={{
              backgroundColor: `${chartColor[index]}`,
            }}
          />
          <div className="legend-font">{dChartTitle[index]}</div>
        </div>
      );
    });
  };

  return (
    <div className="doughnut-chart-container">
      {title && <div className="chart-title">{title}</div>}
      <div className="dn-chart-wrapper">
        <div className="dn-chart-size">
          <Doughnut
            redraw
            ref={doughnut}
            data={chartData}
            options={chartOptions}
            height={null}
            width={null}
            plugins={[drawTextInsideDoughnutPlugin]}
          />
        </div>
        {legendDisplay && (
          <div
            className={classNames("legend", { "legend-custom": customLegend })}
          >
            {generateLegend()}
          </div>
        )}
      </div>
    </div>
  );
};

export default DoughnutChartComponent;

DoughnutChartComponent.propTypes = {
  title: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
  textTop: PropTypes.string,
  textBottom: PropTypes.string,
  legendDisplay: PropTypes.bool,
  customLegend: PropTypes.bool,
  isBottomColor: PropTypes.bool,
};
